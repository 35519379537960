import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from '@/plugins/vuetify' // path to vuetify expor
import axios from 'axios'
import VueMeta from 'vue-meta'
import Swal from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

const serverUrl = 'https://ahmadstore.garduny.group/'
//const serverUrl = 'http://localhost/app-store'
store.state.serverurl = serverUrl
Vue.config.productionTip = false
axios.defaults.baseURL = serverUrl + '/api'

Vue.use(Swal, VueMeta)


new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')









