<template>
    <div>
        <navbar></navbar>
        <v-container fluid class="mb-4">

            <v-row class="text-center printable">
                <v-col cols="12 text-start mx-auto" sm="12" md="12" lg="12" xl="12">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{
                                langkeyword("PRODUCTPROFILE")
                            }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword("NAME") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.en_name }}</span>
                            </h4>
                            <h4 class="d-none">{{ langkeyword("BRAND") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.brands_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.brands_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.brands_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("CATEGORY") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.productcategories_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.productcategories_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.productcategories_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("UNITONE") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.unitsone_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.unitsone_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.unitsone_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("UNITMANY") }} :
                                <span v-if="lang() === 'ku'">{{ productinfo.data.unitsmany_ku_name }}</span>
                                <span v-if="lang() === 'ar'">{{ productinfo.data.unitsmany_ar_name }}</span>
                                <span v-if="lang() === 'en'">{{ productinfo.data.unitsmany_en_name }}</span>
                            </h4>
                            <h4>{{ langkeyword("QUANTITY") }} : {{ productinfo.data.qty }}</h4>
                            <h4>{{ langkeyword("UNITONEFROMMANY") }} : {{ productinfo.data.unit_one_from_many }}</h4>
                            <h4>{{ langkeyword("BARCODE") }} : {{ productinfo.data.barcode }}</h4>
                            <h4>{{ langkeyword("BUYPRICE") }} : {{ dinar(productinfo.data.buy_price_iqd) }}
                                &nbsp;&nbsp;-&nbsp;&nbsp; {{ dollar(productinfo.data.buy_price_usd) }}</h4>
                            <h4>{{ langkeyword("SALEPRICE") }} : {{ dinar(productinfo.data.sale_price_iqd) }}
                                &nbsp;&nbsp;-&nbsp;&nbsp; {{ dollar(productinfo.data.sale_price_usd) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(productinfo.totalDinarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(productinfo.totalDinarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(productinfo.totalDinarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dinar(productinfo.totalDinarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(productinfo.totalDollarSale) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(productinfo.totalDollarSaleCash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(productinfo.totalDollarSaleDebt) }}</h4>
                            <h4>{{ langkeyword('FREE') }}: {{ dollar(productinfo.totalDollarSaleFree) }}</h4>
                        </div>
                    </v-card>
                </v-col>
                <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
                    <v-card elevation="2" outlined shaped>
                        <v-card-title class="mx-auto">
                            <h3>{{ langkeyword("PROFIT") }}</h3>
                        </v-card-title>
                        <div class="pa-2">
                            <h4>{{ langkeyword('TOTAL') }}: {{ dollar(productinfo.totaldollarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dollar(productinfo.totaldollarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dollar(productinfo.totaldollarprofitdebt) }}</h4>
                            <v-divider></v-divider>
                            <h4>{{ langkeyword('TOTAL') }}: {{ dinar(productinfo.totaldinarprofit) }}</h4>
                            <h4>{{ langkeyword('CASH') }}: {{ dinar(productinfo.totaldinarprofitcash) }}</h4>
                            <h4>{{ langkeyword('DEBT') }}: {{ dinar(productinfo.totaldinarprofitdebt) }}</h4>
                        </div>
                    </v-card>
                </v-col>
            </v-row>







            <v-row class="alert mt-4">
                <v-col class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-5 mx-auto text-center" v-if="error_msg != null">
                    <v-alert dense type="warning">{{ error_msg }}</v-alert>
                </v-col>
                <v-col class="col-11 col-sm-11 col-md-6 col-lg-5 col-xl-5 mx-auto text-center"
                    v-if="success_msg != null">
                    <v-alert dense type="success">{{ success_msg }}</v-alert>
                </v-col>
            </v-row>





            <v-form class="row text-center">
                <v-card class="col-11 col-sm-10 col-md-6 col-lg-6 col-xl-6 ma-auto pa-3 rounded mb-3 mt-1 text-center"
                    outlined dense>
                    <v-text-field type="text" hide-spin-buttons outlined dense full-width hide-details=""
                        class="nzar-font ma-1" v-model="barcode" :label="langkeyword('BARCODE')">
                    </v-text-field>
                    <v-btn color="success" large class="ma-1 pa-2" @click="saveBarcode()">
                        <v-icon>mdi-pen</v-icon>
                        {{ langkeyword('SAVE') }}
                    </v-btn>
                </v-card>
            </v-form>


            <v-simple-table class="mt-3 col-11 col-sm-10 col-md-6 col-lg-6 col-xl-6 ma-auto pa-2" dense>
                <thead>
                    <tr>
                        <th class="text-center">
                            {{ langkeyword('BARCODE') }}
                        </th>
                        <th class="text-center">
                            {{ langkeyword('ACTIONS') }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(productbarcode, index) in productbarcodes" :key="index">
                        <td class="text-center">
                            {{ productbarcode.barcode }}
                        </td>
                        <td class="text-center">
                            <v-btn color="red" class="ma-1 white--text" x-small
                                @click="deleteBarcode(productbarcode.id)"><v-icon>mdi-delete</v-icon></v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>





        </v-container>
        <br /><br /><br />
        <dashboardFooter />
    </div>
</template>

<script>
//import Vue from "vue"
import ProductsRequests from "../../../requests/dashboard/product/Products"
import ProductBarcodesRequests from "../../../requests/dashboard/product/ProductBarcodes"
import navbar from "../../../layouts/dashboard/nav.vue"
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue"
export default {
    components: {
        navbar,
        dashboardFooter,
    },
    data() {
        return {
            productinfo: {},
            error_msg: null,
            success_msg: null,
            barcode: '',
        }
    },
    watch: {
        options: {
            handler() {
                this.readData()
            },
        },
    },
    mounted() {
        this.readData()
    },
    computed: {
        productbarcodes() {
            return this.$store.getters.getproductbarcodes.filter(pb => { return pb.product_id == this.$route.params.id })
        },
    },
    methods: {
        cleanMessages() {
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
        readData() {
            ProductsRequests.getOne(
                {
                    getOneProducts: true,
                    product_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg)
                        setTimeout(() => {
                            this.$router.push("/*")
                        }, 1000)
                    } else if (res.data.status === true) {
                        this.error_msg = null
                        this.productinfo = res.data
                    }
                    this.cleanMessages()
                }
            ),
                setTimeout(() => {
                    if (!this.permissionExist("#view_products")) {
                        this.$router.push("/dashboard/home")
                    }
                }, 500)
        },
        saveBarcode() {
            ProductBarcodesRequests.add(
                {
                    addProductBarcodes: true,
                    barcode: this.barcode,
                    product_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.productbarcodes = res.data.data;
                        this.barcode = null;
                    }
                    this.cleanMessages();
                }
            );
        },
        deleteBarcode(item) {
            ProductBarcodesRequests.delete(
                {
                    deleteProductBarcodes: true,
                    productbarcode_id: item,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.productbarcodes = res.data.data;
                        this.barcode = null;
                    }
                    this.cleanMessages();
                }
            );
        }
    },
}
</script>
<style scoped>
@media print {
    .notprintable {
        display: none
    }

    .printable {
        display: block
    }
}
</style>