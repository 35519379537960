<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mt-4 mb-4">
      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="d-flex text-main justify-center">
        <v-col cols="11">
          <v-data-table :headers="headers" :items="expenses" :loading="loading" :search="search" sort-by="name"
            class="elevation-1 nzar-font radius-15" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><v-icon>mdi-cash-multiple</v-icon>
                  {{ langkeyword("EXPENSES") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success"
                      :style="permissionExist('#add_expenses') ? 'color: white;float:right' : 'display:none;'"
                      class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-text-field class="nzar-font" v-model="expensesObject.invoicenumber"
                            :label="langkeyword('INVOICE')">
                          </v-text-field>

                          <v-text-field class="nzar-font" v-model="expensesObject.note" :label="langkeyword('NOTE')">
                          </v-text-field>
                          <v-text-field class="nzar-font" type="number" step="any" min="0"
                            v-model="expensesObject.amount" :label="langkeyword('AMOUNT')">
                          </v-text-field>

                          <v-autocomplete v-if="isAdd() === true || isEdit() === true"
                            v-model="expensesObject.expensecategory_id" :items="expensecategories"
                            :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('EXPENSECATEGORY')" dense></v-autocomplete>

                          <v-autocomplete v-if="isAdd() === true || isEdit() === true"
                            v-model="expensesObject.company_id" :items="companies" :item-text="'name'" item-value="id"
                            :label="langkeyword('COMPANY')" dense></v-autocomplete>

                          <v-select v-if="isAdd() === true || isEdit() === true" v-model="expensesObject.currency_id"
                            :items="currencies" :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('CURRENCY')" dense></v-select>

                          <v-select v-if="isAdd() === true || isEdit() === true" v-model="expensesObject.invoicetype_id"
                            :items="invoicetypes" :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('INVOICETYPE')" dense></v-select>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveExpenses">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                        @click="updateExpenses(expensesObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="deleteExpenses(expensesObject.id)">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog persistent v-model="dialogChangeAvatar" max-width="800px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-upload-circle</v-icon>
                      {{ langkeyword("INVOICEIMAGE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("CHANGEINVOICEIMAGE") }}
                    </v-card-text>
                    <v-card-actions>


                      <v-form @submit.prevent="changeAvatar(expensesObject.id)" ref="form" lazy-validation
                        class="text-center">
                        <v-file-input v-model="avatar" id="invoiceAvatar" name="avatar" :label="langkeyword('AVATAR')"
                          required prepend-icon="mdi-camera"></v-file-input>

                        <v-btn :disabled="avatar == null" x-large type="submit" class="m-auto bg-main white--text">
                          {{ langkeyword("UPLOAD") }}
                        </v-btn>
                      </v-form>

                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeChangeAvatarDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field v-if="permissionExist('#search_expenses')" class="nzar-font" v-model="search"
                      append-icon="mdi-magnify" :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_expenses')" small class="mr-2 orange white--text px-2 py-2 rounded"
                @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#delete_expenses')" small class="mr-2 red white--text px-2 py-2 rounded"
                @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
              <a v-if="permissionExist('#view_expenses') && item.avatar != null" :href="uploadPath(item.avatar)"
                target="_blank">
                <v-icon small class="mr-2 info white--text px-2 py-2 rounded">
                  mdi-image
                </v-icon>
              </a>
              <v-icon v-if="permissionExist('#edit_expenses')" small class="mr-2 success white--text px-2 py-2 rounded"
                @click="changeAvatarDialog(item)">
                mdi-upload-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import ExpensesRequests from "../../../requests/dashboard/expense/Expenses";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    dialogChangeAvatar: false,
    avatar: null,
    snackbar: true,
    expenses: [],
    search: "",
    loading:
      Vue.prototype.getters().getexpenses == null ||
        Vue.prototype.getters().getexpenses == "" ||
        Vue.prototype.getters().getexpenses == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center" },
      {
        text: Vue.prototype.langkeyword("NOTE"),
        value: "note",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("AMOUNT"),
        value: "amount",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("EXPENSECATEGORY"),
        value:
          "expensecategories_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("COMPANY"),
        value: "companies_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("INVOICETYPE"),
        value: "invoicetypes_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("CURRENCY"),
        value: "currencies_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("DATE"),
        value: "created_at",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    expensesObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      invoicenumber: "",
      note: "",
      amount: "",
      expensecategory_id: "",
      company_id: "",
      currency_id: 2,
      invoicetype_id: 2,
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  computed: {
    expensecategories() {
      return this.$store.getters.getexpensecategories;
    },
    companies() {
      return this.isNormal() ? this.$store.getters.getcompanies.filter(c => { return c.user_id == this.userId() }) : this.$store.getters.getcompanies;
    },
    currencies() {
      return this.$store.getters.getcurrencies;
    },
    invoicetypes() {
      return this.$store.getters.getinvoicetypes;
    },
  },
  mounted() {
    this.readData();
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      this.expensesObject = { user_id: Vue.prototype.dashboardAuthId() };
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.expensesObject = Object.assign({}, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.expensesObject = Object.assign({}, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.expensesObject = Object.assign({}, item);
      this.dialogDelete = true;
    },
    changeAvatarDialog(item) {
      this.CURRENTFORM = "changeavatar";
      this.expensesObject = Object.assign({}, item);
      this.dialogChangeAvatar = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.expensesObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.expensesObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeChangeAvatarDialog() {
      this.dialogChangeAvatar = false;
      this.$nextTick(() => {
        this.expensesObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    readData() {
      this.expenses = this.$store.getters.getexpenses;
      setTimeout(() => {
        if (!this.permissionExist("#view_expenses")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
    changeAvatar(id) {
      const filename = document.getElementById("invoiceAvatar").files[0];
      const avatarParams = new FormData();
      avatarParams.append("changeAvatarExpenses", true);
      avatarParams.append("expense_id", id);
      avatarParams.append("avatar", filename);
      const config = {
        headers: {
          "content-type": `multipart/form-data; boundary=${avatarParams._boundary}`,
        },
      };
      ExpensesRequests.changeAvatar(avatarParams, config, (res) => {
        if (res.data.status === false) {
          this.avatar = null;
          this.error_msg = this.langkeyword(res.data.msg);
        } else if (res.data.status === true) {
          document.getElementById("invoiceAvatar").value = null;
          this.avatar = null;
          this.error_msg = null;
          this.success_msg = this.langkeyword(res.data.msg);
          this.$store.state.expenses = res.data.data;
          this.readData();
        }
        this.cleanMessages();
        this.closeChangeAvatarDialog()
      });
    },
    saveExpenses() {
      this.validate(
        [
          this.expensesObject.invoicenumber,
          this.expensesObject.note,
          this.expensesObject.amount,
          this.expensesObject.expensecategory_id,
          this.expensesObject.company_id,
          this.expensesObject.currency_id,
          this.expensesObject.invoicetype_id,
        ],
        () => {
          ExpensesRequests.add(
            {
              addExpenses: true,
              invoicenumber: this.expensesObject.invoicenumber,
              note: this.expensesObject.note,
              amount: this.expensesObject.amount,
              expensecategory_id: this.expensesObject.expensecategory_id,
              company_id: this.expensesObject.company_id,
              currency_id: this.expensesObject.currency_id,
              invoicetype_id: this.expensesObject.invoicetype_id,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.expenses = res.data.data;
                this.expenses = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateExpenses(expenseId) {
      this.validate(
        [
          this.expensesObject.invoicenumber,
          this.expensesObject.note,
          this.expensesObject.amount,
          this.expensesObject.expensecategory_id,
          this.expensesObject.company_id,
          this.expensesObject.currency_id,
          this.expensesObject.invoicetype_id,
        ],
        () => {
          ExpensesRequests.edit(
            {
              editExpenses: true,
              expense_id: expenseId,
              invoicenumber: this.expensesObject.invoicenumber,
              note: this.expensesObject.note,
              amount: this.expensesObject.amount,
              expensecategory_id: this.expensesObject.expensecategory_id,
              company_id: this.expensesObject.company_id,
              currency_id: this.expensesObject.currency_id,
              invoicetype_id: this.expensesObject.invoicetype_id,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.expenses = res.data.data;
                this.expenses = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteExpenses(expenseId) {
      ExpensesRequests.delete(
        { deleteExpenses: true, expense_id: expenseId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.expenses = res.data.data;
            this.expenses = res.data.data;
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
    openInvoiceAvatarBox() { },
    openInvoiceAvatar() { },
  },
};
</script>
