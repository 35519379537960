import Vue from 'vue'
import Vuex from 'vuex'
import AppRequests from '../requests/App'
Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    actlang: null,
    serverurl: null,
    device: null,
    dashboardauth: {},
    dashboardhome: {},
    setting: {},
    users: [],
    userstokens: [],
    roles: [],
    companies: [],
    customers: [],
    menus: [],
    menusbypermissions: [],
    pages: [],
    permissions: [],
    cities: [],
    stores: [],
    units: [],
    productcategories: [],
    brands: [],
    products: [],
    expenses: [],
    expensecategories: [],
    currencies: [],
    invoicetypes: [],
    companyinvoices: [],
    companyinvoiceitems: [],
    currentcompanyinvoicedetail: {},
    customerinvoices: [],
    customerinvoiceitems: [],
    currentcustomerinvoicedetail: {},
    productlisting: [],
    fastsalelist: [],
    productbarcodes: [],
    treasures: [],
    direction: '',
  },
  getters: {
    getdevice(state) {
      return state.device
    },
    getusers(state) {
      return state.users
    },
    getuserstokens(state) {
      return state.userstokens
    },
    getroles(state) {
      return state.roles
    },

    getdashboardauth(state) {
      return state.dashboardauth
    },
    getlang(state) {
      return state.lang
    },
    getdrirection(state) {
      return state.drirection
    },
    getsetting(state) {
      return state.setting
    },
    getdashboardhome(state) {
      return state.dashboardhome
    },
    getcompanies(state) {
      return state.companies
    },
    getcustomers(state) {
      return state.customers
    },
    getmenus(state) {
      return state.menus
    },
    getmenusbypermissions(state) {
      return state.menusbypermissions
    },
    getpages(state) {
      return state.pages
    },
    getpermissions(state) {
      return state.permissions
    },
    getcities(state) {
      return state.cities
    },
    getstores(state) {
      return state.stores
    },
    getunits(state) {
      return state.units
    },
    getproductcategories(state) {
      return state.productcategories
    },
    getbrands(state) {
      return state.brands
    },
    getexpensecategories(state) {
      return state.expensecategories
    },
    getexpenses(state) {
      return state.expenses
    },
    getproducts(state) {
      return state.products
    },
    getcurrencies(state) {
      return state.currencies
    },
    getinvoicetypes(state) {
      return state.invoicetypes
    },
    getcompanyinvoices(state) {
      return state.companyinvoices
    },
    getcompanyinvoiceitems(state) {
      return state.companyinvoiceitems
    },
    getcurrentcompanyinvoicedetail(state) {
      return state.currentcompanyinvoicedetail
    },
    getcustomerinvoices(state) {
      return state.customerinvoices
    },
    getcustomerinvoiceitems(state) {
      return state.customerinvoiceitems
    },
    getcurrentcustomerinvoicedetail(state) {
      return state.currentcustomerinvoicedetail
    },
    getproductlisting(state) {
      return state.productlisting
    },
    getfastsalelist(state) {
      return state.fastsalelist
    },
    getproductbarcodes(state) {
      return state.productbarcodes
    },
    gettreasures(state) {
      return state.treasures
    },
  },
  mutations: {
    fetchData(state) {
      AppRequests.getAll({ isLoadApp: true }, (res) => {
        state.device = res.data.device
        state.setting = res.data.fetchsetting
        state.users = res.data.getusers
        state.userstokens = res.data.getuserstokens
        state.roles = res.data.getroles
        state.companies = res.data.getcompanies
        state.customers = res.data.getcustomers
        state.menus = res.data.getmenus
        state.pages = res.data.getpages
        state.permissions = res.data.getpermissions
        state.cities = res.data.getcities
        state.stores = res.data.getstores
        state.products = res.data.getproducts
        state.expenses = res.data.getexpenses
        state.units = res.data.getunits
        state.productcategories = res.data.getproductcategories
        state.brands = res.data.getbrands
        state.expensecategories = res.data.getexpensecategories
        state.currencies = res.data.getcurrencies
        state.invoicetypes = res.data.getinvoicetypes
        state.companyinvoices = res.data.getcompanyinvoices
        // state.companyinvoiceitems = res.data.getcompanyinvoiceitems
        state.customerinvoices = res.data.getcustomerinvoices
        state.productbarcodes = res.data.getproductbarcodes
        state.treasures = res.data.gettreasures
        //   state.customerinvoiceitems = res.data.getcustomerinvoiceitems
      })
      AppRequests.countAll({ isLoadApp: true }, (res) => {
        state.dashboardhome = res.data
      })
    }
  },
  actions: {

  },
  modules: {
  }
})
