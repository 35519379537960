<template>
    <div>
        <v-container fluid class="mb-4">
            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto notprintable" sm="11">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                    <v-btn onclick="window.print()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text primary" x-large>
                        <v-icon> mdi-printer</v-icon>
                        {{ langkeyword("BACK") }}
                    </v-btn>
                </v-col>
                <v-col cols="12 text-start mx-auto" sm="12" class="printbg white rounded rounded">
                    <v-card color="transparent" elevation="0" outlined shaped>

                        <v-row>
                            <div class="col-10 col-sm-6 col-md-6 col-lg-6 col-xl-6 pa-1 m-auto text-justify">
                                <v-card-title class="mx-auto">
                                    <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'ku'">
                                        {{ $store.state.setting.ku_name }}
                                    </h2>
                                    <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'ar'">
                                        {{ $store.state.setting.ar_name }}
                                    </h2>
                                    <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'en'">
                                        {{ $store.state.setting.en_name }}
                                    </h2>
                                </v-card-title>
                                <br />
                                <v-card-text>
                                    <h3 class="dark--text">
                                        {{ langkeyword("PHONE") }} :
                                        {{ $store.state.setting.phone1 }}
                                        -
                                        {{ $store.state.setting.phone2 }}
                                        <br /><br />
                                    </h3>
                                    <h3 class="dark--text"
                                        v-if="$store.state.setting.ku_address != null && $store.state.setting.ku_address != ''">
                                        {{ langkeyword("ADDRESS") }} :
                                        <span v-if="lang() === 'en'">{{ $store.state.setting.en_address }}</span>
                                        <span v-if="lang() === 'ar'">{{ $store.state.setting.ar_address }}</span>
                                        <span v-if="lang() === 'ku'">{{ $store.state.setting.ku_address }}</span>
                                        <br /><br />
                                    </h3>
                                    <h3 class="dark--text"
                                        v-if="$store.state.setting.email != null && $store.state.setting.email != ''">
                                        {{ langkeyword("EMAIL") }} :
                                        {{ $store.state.setting.email }}
                                        <br /><br />
                                    </h3>
                                    <h3 class="dark--text"
                                        v-if="$store.state.setting.licensenumber != null && $store.state.setting.licensenumber != ''">
                                        {{ langkeyword("LICENSENUMBER") }} :
                                        {{ $store.state.setting.licensenumber }}
                                        <br /><br />
                                    </h3>
                                    <h3 class="dark--text"
                                        v-if="$store.state.setting.website != null && $store.state.setting.website != ''">
                                        {{ langkeyword("WEBSITE") }} :
                                        {{ $store.state.setting.website }}
                                        <br /><br />
                                    </h3>
                                </v-card-text>
                            </div>
                            <div class="col-10 col-sm-6 col-md-3 col-lg-2 col-xl-2 pa-1 ma-auto text-center">
                                <img :src="uploadPath(this.$store.state.setting.logo)
                                    " alt="logo" style="width: 100%;height:100%;" />
                            </div>
                        </v-row>


                        <v-row>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                                <h3>{{ langkeyword("CUSTOMER") }} : {{ customerinfo.name }}</h3>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                                <h3>{{ langkeyword("PHONE") }} : {{ customerinfo.phone }}</h3>
                            </div>
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                                <h3>{{ langkeyword("ADDRESS") }} : {{ customerinfo.address }}</h3>
                            </div>
                        </v-row>
                        <hr />
                        <v-row>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-4 ma-auto text-center">
                                <v-simple-table>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-center " style="font-size:1.2rem;">
                                                    {{ langkeyword("INVOICENUMBER") }}
                                                </th>
                                                <th class="text-center " style="font-size:1.2rem;">
                                                    {{ langkeyword("DATE") }}
                                                </th>
                                                <th class="text-center " style="font-size:1.2rem;">
                                                    {{ langkeyword("INVOICETYPE") }}
                                                </th>
                                                <th class="text-center " style="font-size:1.2rem;">
                                                    {{ langkeyword("CURRENCY") }}
                                                </th>
                                                <th class="text-center " style="font-size:1.2rem;">
                                                    {{ langkeyword("TOTAL") }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody style="zoom:90%;">
                                            <tr v-for="(ci, index) in customerinvoices" :key="index">
                                                <td style="font-size:1.1rem;">{{ ci.invoicenumber }}</td>
                                                <td style="font-size:1.1rem;">{{ ci.date }}</td>
                                                <td style="font-size:1.1rem;">
                                                    <span v-if="lang() === 'en'">{{ ci.invoicetypes_en_name }}</span>
                                                    <span v-if="lang() === 'ar'">{{ ci.invoicetypes_ar_name }}</span>
                                                    <span v-if="lang() === 'ku'">{{ ci.invoicetypes_ku_name }}</span>
                                                </td>
                                                <td style="font-size:1.1rem;">
                                                    <span v-if="lang() === 'en'">{{ ci.currencies_en_name }}</span>
                                                    <span v-if="lang() === 'ar'">{{ ci.currencies_ar_name }}</span>
                                                    <span v-if="lang() === 'ku'">{{ ci.currencies_ku_name }}</span>
                                                </td>
                                                <td>
                                                    {{ ci.currency_id === 1 ? dinar(ci.totalinvoice) :
                                                        dollar(ci.totalinvoice) }}
                                                </td>
                                            </tr>
                                            <hr>
                                            <tr>
                                                <th class="text-center" style="font-size:1.2rem;">{{ langkeyword("SALE")
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{ dinar(customerinfo.totalDinarSale) }}
                                                </th>
                                                <th style="font-size:1.2rem;">{{ dollar(customerinfo.totalDollarSale) }}
                                                </th>
                                            </tr>
                                            <tr>
                                                <th class="text-center" style="font-size:1.2rem;">{{ langkeyword("CASH")
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{ dinar(customerinfo.totalDinarSaleCash)
                                                    }}</th>
                                                <th class="text-center" style="font-size:1.2rem;">{{ langkeyword("DEBT")
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{ dinar(customerinfo.totalDinarSaleDebt)
                                                    }}</th>
                                            </tr>
                                            <tr>
                                                <th class="text-center" style="font-size:1.2rem;">{{ langkeyword("CASH")
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{
                                                    dollar(customerinfo.totalDollarSaleCash)
                                                    }}</th>
                                                <th class="text-center" style="font-size:1.2rem;">{{ langkeyword("DEBT")
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{
                                                    dollar(customerinfo.totalDollarSaleDebt)
                                                    }}</th>
                                            </tr>

                                            <tr>
                                                <th class="text-center" style="font-size:1.2rem;">{{
                                                    langkeyword("RETURNDEBT")
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{ dinar(customerinfo.totalbackdebtiqd)
                                                    }}</th>
                                                <th style="font-size:1.2rem;">{{ dollar(customerinfo.totalbackdebtusd)
                                                    }}</th>
                                            </tr>

                                            <tr>
                                                <th class="text-center" style="font-size:1.2rem;">{{
                                                    langkeyword("IQDDEBT") }}</th>
                                                <th style="font-size:1.2rem;">{{ dinar(customerinfo.iqddebt) }}</th>
                                                <th class="text-center" style="font-size:1.2rem;">{{
                                                    langkeyword("USDDEBT") }}</th>
                                                <th style="font-size:1.2rem;">{{ dollar(customerinfo.usddebt) }}</th>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </div>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <br /><br /><br />
    </div>
</template>

<script>
import CustomerRequests from "../../../requests/dashboard/debt/Customers";
import CustomerInvoicesRequests from "../../../requests/dashboard/sale/CustomerInvoices";

export default {
    data() {
        return {
            customerinfo: {},
            customerinvoices: [],
        };
    },
    watch: {
        /*
              filter: (value) => {
                return value == this.$route.params.id;
              },
    */

        options: {
            handler() {
                this.readData();
            },
        },
    },
    mounted() {
        this.readData();
    },
    computed: {
    },
    methods: {
        readData() {
            CustomerRequests.getOne(
                {
                    getOneCustomers: true,
                    customer_id: this.$route.params.id,
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                        setTimeout(() => {
                            this.$router.push("/*");
                        }, 1000);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.customerinfo = res.data.data;
                    }
                }
            ),

                this.customerinvoices = this.$store.getters.getcustomerinvoices.filter(
                    (data) => {
                        CustomerInvoicesRequests.getOne(
                            {
                                getOneCustomerInvoices: true,
                                customerinvoice_id: data.id,
                            },
                            (res) => {
                                data.totalinvoice = res.data.invoicetotal
                            }
                        )
                        return data.customer_id == this.$route.params.id;
                    }
                );
        },
    },
};
</script>
<style scoped>
.container,
.container-fluid {
    background: white;
}

/* img {
    display: none;
} */

@media print {
    .notprintable {
        display: none;
    }

    img {
        display: block;
    }
}

.printbg {
    color: black;
}
</style>